/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* layout */
:root {
  --primary: #1aac83;
  --error: #e7195a;
}
/* Added by me */
* {
  margin: 0;
  padding: 0;
}
body {
  background: #f1f1f1;
  margin: 0;
  font-family: "Poppins";
}
p {
  color: black;
}
a {
  color: black;
  text-decoration: none;
  text-decoration-color: white;
}
a:hover {
  color: #1aac83;
  text-decoration: underline;
  text-underline-offset: 1 rem;
  text-decoration-color: #1aac83;
}



/* TRANSITION */
a {
    transition: all 300ms ease;
}
header {
  background: #fff;
  height: 100px;
  max-height: 100px;
}
header .container {
  max-width: 1400px;
  max-height: 100px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header a {
  color: #333;
  text-decoration: none;
}
header .logo {
    font-size: 2rem;
    font-weight: bold;
    padding: 20px;
}
.logo a {
  text-decoration: none;
}
header .logo:hover {
    cursor:default;
    text-decoration: none;
}

.pages{
  max-width: 1400px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}

/* homepage */
.home {
  padding-top: 100px;
  position: relative;
  display:flex;
  position:relative;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
}

.canvas1{
  position:fixed;
  z-index: -1;
  top:100px;
  left:0px;
  width:100%;
  height:100%;
}
.active {
  color: #1aac83;
  font-weight:bold;
  text-align: center;
}
.activeUsers {
  
  padding-top: 20px;
  padding-bottom: 40px;
  text-align: center;
  justify-content: center;
}
.activeUsers span {
  color:#1aac83;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;  /* Preferred icon size */
}
.activeUsers p {
  color:#1aac83;
}
.startnow {
  padding-top: 30px;
  padding-bottom: 40px;
  text-align: center;
  justify-content: center;
}
.startnow button {
  width:160px;
  height:55px;
  font-size: large;
  position: relative;
}
.startnow button:hover {
  box-shadow: 0 0 15px #1aac83;
  color: #1aac83;
  background: #f1f1f1;
  border: 2px solid #1aac83;
}
.checkProjects {
  text-align: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 40px;
}
.checkProjects button {
  
  width:180px;
  height:50px;
  font-size: large;
  position: relative;
  vertical-align: middle;
}
.checkProjects button:hover {
  box-shadow: 0 0 15px #1aac83;
  color: #1aac83;
  background: #f1f1f1;
  border: 2px solid #1aac83;
}



/* about */
.about {
  display: flex;
  position:relative;
  max-width: 100%;
  gap: 30px;
  flex-direction: column;
  padding-top: 2vh;
  align-items: center;
}

/* projects */
.projects {
  display: flex;
  justify-content: center;
  max-width: 100%;
  gap: 30px;
  flex-direction: row;
  padding-top: 2vh;
}
.createForm {
  text-align: center;
}

.projectButtons form button {
  margin-right: 15px; 
  position: relative;
  display: inline-block;
  background-color: #1aac83;
  color: white;
  font-weight: bold;
  border: 2px solid #1aac83;
}
.projectButtons form button:hover {
  box-shadow: 0 0 15px #1aac83;
  color: #1aac83;
  background: #f1f1f1;
  border: 2px solid #1aac83;
}
.projectButtons form.createButtonForm {
  max-width: 365px;
  margin: 20px auto;
}
.projectButtons form.createProjectForm {
  border: 3px solid #1aac83;
  box-shadow: 0 0 10px #1aac83;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  margin: 20px auto;
  background: #fff;
  gap: 0.5rem;
  position: relative; 
}

.projectButtons form.createProjectForm button {
  background-color: #1aac83;
  color: white;
  font-weight: bold;
}
.projectButtons form.createProjectForm button:hover {
  box-shadow: 0 0 10px #1aac83;
  color: #1aac83;
  background: white;
  border: 2px solid #1aac83;
}
.projectButtons form.createStaffForm {
  border: 3px solid #1aac83;
  box-shadow: 0 0 10px #1aac83;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  margin: 20px auto;
  background: #fff;
  gap: 0.5rem;
}
.projectButtons form.createStaffForm button {
  background-color: #1aac83;
  color: white;
  font-weight: bold;
}
.projectButtons form.createStaffForm button:hover{
  box-shadow: 0 0 10px #1aac83;
  color: #1aac83;
  background: white;
  border: 2px solid #1aac83;
}
/* myprojects */
.myprojects {
  justify-content: center;
  padding-top: 2vh;
  height: 96vh;
}
.project-details .project-buttons {
  margin-left: 30px;
  margin-bottom: 20px;
}
.project-details .project-buttons span{
  position: relative;
  display: inline-block;
  gap: 30px;
}
.project-details .project-buttons span:last-child{
  position: relative;
  display: inline-block;
  gap: 30px;
}
.project-details {
  background: #fff;
  border-radius: 10px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px #1aac83;
  border: 3px solid #1aac83;
}
.project-details :hover{

  text-decoration: none;

}
.project-details h4 {

  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);

}
.project-details p {

  margin: 0;
  font-size: 0.9em;
  color: #555;

}
.project-details span {

  position: relative;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 6px;
  border-radius: 50%;
  color: black;

}
.project-details span:hover{
  
  background-color: #D4300D;
  color: white;

}
.project-details a.book span:hover{
  
  background-color: #2980B9;
  color: white;
 
}
.project-details a.calendar span:hover{
  
  background-color: #1aac83;
  color: white;
 
}
.project-details a.staff span:hover{
  
  background-color: #EC8B35;
  color: white;
 
}

.createDiaryForm select {

  margin-bottom: 20px;
  height: 40px;
  width: 100%;

}

.createProjectForm select {

  margin-bottom: 20px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  
}

.createStaffForm select {

  margin-bottom: 20px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;

}


/* new project form */
label, input {

  display: block;
  

}
input {

  padding: 10px;
  margin-top: 3px;
  margin-bottom: 15px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;

}
input:focus {
    outline: none !important;
    border:1px solid #ddd;
    box-shadow: 0 0 5px #1aac83;
}
select:focus {
    outline: none !important;
    border:1px solid #ddd;
    box-shadow: 0 0 5px #1aac83;
}
textarea:focus {
    outline: none !important;
    border:1px solid #ddd;
    box-shadow: 0 0 5px #1aac83;
}
form button {

  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 10px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;

}
form li {

  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 10px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  align-items: center;

}

.project-details span :hover{

  font-size: 1.5rem;
  background-color: #D4300D;
  transition: all 0.3 ease-in-out;

}
.diary-details span :hover{

  font-size: 1.5rem;
  background-color: #D4300D;
  transition: all 0.3 ease-in-out;

}
div.error {

  padding: 10px;
  background: #ffefef;
  border: 2px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 10px 0;

}
input.error {

  border: 2px solid var(--error);

}
select.error{

  border: 2px solid var(--error);

}




/* diaries */
.diaries {

  display: flex;
  justify-content: center;
  max-width: 100%;
  gap: 30px;
  flex-direction: column;
  padding-top: 2vh;
  
}
/* mydiaries */
.mydiaries {

  justify-content: center;
  height: 96vh;

}
.diariesHistory {
  float:flex;
  margin-top: 50px;
  align-self: center;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  text-decoration-line: underline;
  text-decoration-thickness: 5%;
}
.diariesHistory button {
  border: 2px solid #1aac83;
  color: white;
  background: #1aac83;
  margin-top: 10px;
  margin-bottom: 10px;
  
}
.diariesHistory button:hover {
  box-shadow: 0 0 15px #1aac83;
  color: #1aac83;
  background: #f1f1f1;
  border: 2px solid #1aac83;
}
.wrapper {
  padding: 10px;
  position: relative;
  overflow-x: hidden;
  max-width: 100%;
  margin-left: 55px;
  margin-right: 55px;
  background: #f1f1f1;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
}
.wrapper .icon {
  position: absolute;
  top: 0;
  height: 100%;
  width: 80px;
  display: flex;
  align-items: center;
}
.diaryForm .icon:first-child {
  left: 0;
  background: linear-gradient(90deg, #f1f1f1 70%, transparent);
}
.diaryForm .icon:last-child {
  right: 0;
  justify-content: flex-end;
  background: linear-gradient(-90deg, #f1f1f1 70%, transparent);
}
.diaryForm .icon i {
  color: white;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 1.2rem;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  background: #1aac83;
  border: 2px solid #1aac83;
}
.diaryForm .icon i:hover {
  color: #1aac83;
  font-weight: bolder;
  background: #f1f1f1;
  border: 2px solid #1aac83;
}
.diaryForm .icon:first-child i{
  margin-left: 10px;
}
.diaryForm .icon:last-child  i{
  margin-right: 10px;
}
.wrapper .tabs-box {
  display: flex;
  gap: 12px;
  list-style: none;
  margin-left: 50px;
  margin-right: 50px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.tabs-box.dragging {
  scroll-behavior: auto;
  cursor: grabbing;
}
.tabs-box .tab {
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  font-size: 0.9rem;
  background: #1aac83;
  padding: 10px 10px;
  border-radius: 15px;
  border: 2px solid #1aac83;
  vertical-align: middle;
  color: white;
  box-sizing: border-box;
  text-decoration: none;

}
.tabs-box.dragging .tab {
  user-select: none;
}
.tabs-box a.tab:hover {
  color: #1aac83;
  background: #f1f1f1;
  border: 2px solid #1aac83;
}


.date{
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.react-datepicker-wrapper{
  width: 230px;
}
.date span{
  padding-left: 15px;
  color: #1aac83;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;  /* Preferred icon size */
}
.time-schedule{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.time-schedule span{
  padding-left: 15px;
  color: #1aac83;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;  /* Preferred icon size */
}
.weather{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.weather span{
  padding-left: 15px;
  color: #1aac83;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;  /* Preferred icon size */
}
.temperature{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.temperature span{
  padding-left: 15px;
  color: #1aac83;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;  /* Preferred icon size */
}
.contractor{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.contractor span{
  padding-left: 15px;
  color: #1aac83;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;  /* Preferred icon size */
}
.subcontractor{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.subcontractor span{
  padding-left: 15px;
  color: #1aac83;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;  /* Preferred icon size */
}
.machineries{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.machineries span{
  padding-left: 15px;
  color: #1aac83;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;  /* Preferred icon size */
}
.materials{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.materials span{
  padding-left: 15px;
  color: #1aac83;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;  /* Preferred icon size */
}
.instructions{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.instructions span{
  padding-left: 15px;
  color: #1aac83;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;  /* Preferred icon size */
}
.description{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.description span{
  padding-left: 15px;
  color: #1aac83;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;  /* Preferred icon size */
}
.overtime{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.overtime span{
  padding-left: 15px;
  color: #1aac83;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;  /* Preferred icon size */
}
.significant{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.significant span{
  padding-left: 15px;
  color: #1aac83;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;  /* Preferred icon size */
}
.visitors{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.visitors span{
  padding-left: 15px;
  color: #1aac83;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;  /* Preferred icon size */
}
.accidents{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.accidents span{
  padding-left: 15px;
  color: #1aac83;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;  /* Preferred icon size */
}
.delay{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.delay span{
  padding-left: 15px;
  color: #1aac83;
  font-weight: normal;
  font-style: normal;
  font-size: 28px;  /* Preferred icon size */
}
.signature{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  
  justify-content: center;
  
  

}
.signature span{
  padding-left: 15px;
  color: #1aac83;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;  /* Preferred icon size */
}


.Section0{
  justify-content: center;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 5px;
  flex-direction: row;
}
.Section1{
  justify-content: center;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 25px;
  flex-direction: row;
  padding-top: 2vh;
  
}
.mainContractor {

  border: 2px solid #1aac83;
  border-radius: 20px;
  padding: 15px;

}
.Section1_1 {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 20px;
  flex-direction: row;
  padding-top: 5px;
}
.Section1_2 {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 20px;
  flex-direction: row;
  padding-top: 5px;
}
.Section1_3 {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 20px;
  flex-direction: row;
  padding-top: 5px;
}
.Section2 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 20px;
  flex-direction: row;
  padding-top: 2vh;
}
.Section2_M {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 20px;
  flex-direction: row;
  padding-top: 2vh;
}
.Section3 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 20px;
  flex-direction: row;
  padding-top: 2vh;
}
.allSubcontractors {
  border: 2px solid #1aac83;
  border-radius: 20px;
  padding: 15px;
}
.Section3_M {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 20px;
  flex-direction: row;
  padding-top: 2vh;
}
.Section4 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 20px;
  flex-direction: row;
  border: 2px solid #1aac83;
  border-radius: 20px;
  padding: 15px;
  align-content: center;
  justify-content: center;
}
.Section4 textarea{
  background: white;
  border: 2px solid lightgrey;
  overflow-y: scroll;
  resize: vertical;
  min-height: 20em;
  min-width: 30em;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  padding: 10px;
}
.Section5 {
  justify-content: center;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.inspection {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 20px;
  flex-direction: row;
  border: 2px solid #1aac83;
  border-radius: 20px;
  padding: 15px;
  align-content: center;
  justify-content: center;
}
.inspection textarea{
  background: white;
  border: 2px solid lightgrey;
  overflow-y: scroll;
  resize: vertical;
  min-height: 20em;
  min-width: 30em;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  padding: 10px;
}
.centerDescription {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 20px;
  flex-direction: row;
  padding: 15px;
  align-content: center;
  justify-content: center;
}
.centerDescription textarea{
  background: white;
  border: 2px solid lightgrey;
  overflow-y: scroll;
  resize: vertical;
  min-height: 20em;
  min-width: 30em;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  padding: 10px;
}
.overtimeWork {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 20px;
  flex-direction: row;
  border: 2px solid #1aac83;
  border-radius: 20px;
  padding: 15px;
  align-content: center;
  justify-content: center;
}
.overtimeWork textarea{
  background: white;
  border: 2px solid lightgrey;
  overflow-y: scroll;
  resize: vertical;
  min-height: 20em;
  min-width: 30em;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  padding: 10px;
}
.Section7 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 20px;
  flex-direction: row;
}
.significantWorks {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 20px;
  flex-direction: row;
  border: 2px solid #1aac83;
  border-radius: 20px;
  padding: 15px;
  align-content: center;
  justify-content: center;
}
.significantWorks textarea {
  background: white;
  border: 2px solid lightgrey;
  overflow-y: scroll;
  resize: vertical;
  min-height: 20em;
  min-width: 30em;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  padding: 10px;
}
.Section8 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 20px;
  flex-direction: row;
  border: 2px solid #1aac83;
  border-radius: 20px;
  padding: 15px;
  align-content: center;
  justify-content: center;
}
.Section8 textarea {
  background: white;
  border: 2px solid lightgrey;
  overflow-y: scroll;
  resize: vertical;
  min-height: 20em;
  min-width: 30em;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  padding: 10px;
}
.centerSignature{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 20px;
  flex-direction: row;
  padding: 15px;
  align-content: center;
  justify-content: center;
}
.canvas .sigCanvas {
  width: 25em;
  height: 16em;
  border: 2px solid lightgrey;
}
.canvas .error {
  border: 2px solid var(--error);
  width: 25em;
  height: 16.3em;
}
.signatureButtons {
  text-align: center;
  margin-top: 10px;
}
.signatureButtons button {
  background-color: #1aac83;
  border: 2px solid #1aac83;
  font-weight: bold;
  color: white;
  width: 100px;
  border-radius: 10px;
}
.signatureButtons button:hover {
  color: #1aac83;
  background: #f1f1f1;
}
.diary-details .project-buttons {
  margin-left: 30px;
  float:inline-end;
}
.diary-details .project-buttons span{
  position: relative;
  display: inline-block;
  gap: 20px;
}
.staff-details .project-buttons {
  margin-left: 30px;
  float:inline-end;
  
}
.staff-details .project-buttons span{
  position: relative;
  display: inline-block;
  gap: 30px;
}
.diary-details {
  background: #fff;
  border-radius: 10px;
  margin: 20px auto;
  padding: 10px;
  width: 60%;
  height: 70px;
  position: relative;
  box-shadow: 2px 2px 5px #1aac83;
  border: 3px solid #1aac83;

}
.staff-details {
  background: #fff;
  border-radius: 10px;
  margin: 20px auto;
  padding: 10px;
  width: 60%;
  height: 90px;
  position: relative;
  box-shadow: 2px 2px 5px #1aac83;
  border: 3px solid #1aac83;
}

.diary-details :hover{
  
  text-decoration: none;

}
.staff-details :hover{
  
  text-decoration: none;

}
.diary-details .diary-content {
  float:inline-start
}
.diary-details .diary-content h4 {

  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);

}
.diary-details .diary-content p {

  margin: 0;
  font-size: 0.9em;
  color: #555;

}
.staff-details .staff-content {

  float:inline-start;

}
input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
  padding: 10px;
  width:fit-content;
}
.checkboxtext {
  
  display:inline-flex;
  min-width: fit-content;
  gap: 10px;

}
.checkboxtext h4 {

  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);

}
.checkboxtext input
{
  /* Checkbox text */
  float:right;

}
.checkboxtext input:focus
{
  outline: none !important;
  border:none;
  box-shadow: none;

}
.staff-details .staff-content p {

  margin: 0;
  font-size: 0.9em;
  color: #555;

}
.diary-details span {

  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 6px;
  border-radius: 50%;
  color: black;

}
.staff-details span {

  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 6px;
  border-radius: 50%;
  color: black;

}
.diary-details span:hover{
  
  background-color: #D4300D;
  color: white;
 
}
.staff-details span:hover{
  
  background-color: #D4300D;
  color: white;
 
}
.diary-details a.pdf span:hover{
  
  background-color: #1aac83;
  color: white;
 
}

.createDiaryForm textarea{
    background: white;
    border: 2px solid lightgrey;
    overflow-y: scroll;
    resize: vertical;
    min-height: 20em;
    min-width: 40em;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    padding: 10px;
}

.centerInstruction{
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
  align-self: center;
}
.submitDiary {
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
  align-self: center;
  padding-top: 20px;
  padding-bottom: 10px;
}
.submitDiary button {
  max-height: 60px;
  font-weight: bold;
  font-size: large;
  border: 2px solid #1aac83;
  border-radius: 15px;
}
.submitDiary button:before {
  content: 'Καταχώρηση Πληροφοριών';
}
.submitDiary button:hover {
  box-shadow: 0 0 15px #1aac83;
  color: #1aac83;
  background: #f1f1f1;
}

.createDiaryForm form div.error {
  border: 2px solid var(--error);
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
  align-self: center;
  margin-left: 70px;
  margin-right: 70px;
}

/* navbar */
nav,
.nav-links {

  display: flex;
  align-items: center;

}
nav a {

  margin-left: 10px;

}
nav button {

  background: #fff;
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 6px 10px;
  border-radius: 4px;
  font-family: "Poppins";
  cursor: pointer;
  font-size: 1em;
  margin-left: 10px;

}
/* addition to nav */
.nav-links {

    gap: 2rem;
    list-style: none;
    font-size: 1.5rem;

}
.nav-links-connect {

  gap: 2rem;
  list-style: none;
  font-size: 1.4rem;

}
.connected button {

    width: 110px;
    height: 50px;

}
.connected span {

  vertical-align: middle;
  font-weight: normal;
  font-style: normal;
  padding-left: 10px;
  font-size: 30px;  /* Preferred icon size */

}
.connected span:hover {

  cursor: pointer;
  color: #1aac83;
  text-decoration: none;

}

.desktop-nav {

  justify-content: space-around;
  align-items: center;
  height: 10vh;

}

/* HAMBURGER MENU */
.hamburger-nav {

  display: none;

}
.hamburger-menu {

  position: relative;
  display: inline-block;

}
.hamburger-icon {

  display: flex; 
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
  cursor: pointer;

}
.hamburger-icon span {

  width: 100%;
  height: 2px;
  background-color: black;
  transition: all 0.3 ease-in-out;

}
.menu-links {

  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  width: fit-content;
  max-height: 0;
  overflow: hidden;
  align-items: center;
  transition: all 0.3 ease-in-out;

}
.menu-links button {
  
  width:85%;
  margin-bottom: 10px;
  margin-top: 10px;

}
.menu-links a {

  display: block;
  padding: 5px;
  text-align: center;
  font-size: 1.4rem;
  color: black;
  text-decoration: none;
  transition: all 0.3 ease-in-out;

}
.menu-links li {

  list-style: none;
  align-items: center;

}
.menu-links.open {

  max-height: 250px;
  z-index:100;
  
}
.hamburger-icon.open span:first-child {
  transform: rotate(45deg) translate(10px, 5px);
}
.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
}
.hamburger-icon.open span:last-child {
  transform: rotate(-45deg) translate(10px, -5px);
}
.hamburger-icon span:first-child {
  transform: none;
}
.hamburger-icon span:first-child {
  opacity: 1;
}
.hamburger-icon span:first-child {
  transform: none;
}

/* SECTIONS */
section {

  padding-top: 3vh;
  height: 96vh;
  margin: 0 10rem;
  box-sizing: border-box;
  min-height: fit-content;

}
.section-container {

  display: flex;

}

/* ICONS */
.icon {

  cursor: pointer;
  height: 2rem;

}


/* auth forms */
form.signup, form.login {
  
  max-width: 450px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  border: 2px solid #1aac83;
  border-radius: 10px;
  box-sizing: border-box;
  font-weight:bold;
  text-align: center;

}

.signup input:last-child{
  margin-bottom: 5px;
}

.signup p {
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;
  text-align: center;
  font-size:15px;
  font-weight:normal;
  color: black;
  background: none;
  padding: 0px;
  cursor: default;
  float:flex;
  position: relative;
  list-style:none;
  margin-top: 10px;
}
.login p {
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;
  text-align: center;
  font-size:15px;
  font-weight:normal;
  color: black;
  background: none;
  padding: 0px;
  cursor: default;
  float:flex;
  list-style:none;
  margin-top: 10px;
}
.login p a {
  font-size:medium;
  color:#1aac83;
  color: black;
  background: none;
  padding: 0px;
  float:flex;
  cursor: default;
  cursor:pointer;
  list-style:none;
  margin-left: 5px;
  color:#1aac83;
  font-weight: bold;
}
.passwordConditions li {
  font-size:small;
  font-weight:normal;
  color: black;
  background: none;
  padding: 0px;
  cursor: default;
  float: inline-start;
  position: relative;
  list-style:none;
  margin-left: 20px;
}
.passwordConditions li:last-child{
  padding-bottom: 30px;
}
form.login h3 {
  text-align: center;
  font-size: 30px;
  color: #1aac83;
  text-decoration-line: underline;
  text-decoration-thickness: 5%;
  text-decoration-color: #1aac83;
}
form.login label {
  text-align: left;
}
form.signup h3 {
  text-align: center;
  font-size: 30px;
  color: #1aac83;
  text-decoration-line: underline;
  text-decoration-thickness: 5%;
  text-decoration-color: #1aac83;
}
form.signup label {
  text-align: left;
}
form.signup button, form.login button {
  height: 30px;
  width: 300px;
  justify-items: center;
  font-size: 16px;
  
  padding-bottom: 35px;
  font-weight:bold;
  border: 2px solid #1aac83;

}
form.login button:hover {
  
  box-shadow: 0 0 10px #1aac83;
  color: #1aac83;
  background: white;
  border: 2px solid #1aac83;

}
form.signup button:hover {
  
  box-shadow: 0 0 10px #1aac83;
  color: #1aac83;
  background: white;
  border: 2px solid #1aac83;

}


/* CONTACT */
.contact {

  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 3vh;
  height: 96vh;

}
.contact-info-upper-container {

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  max-width: 100%;
  border-radius: 2rem;
  background: (250, 250, 250);
  margin: 2rem auto;
  padding: 0.5rem;

}
.contact-info-container {

  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem;

}
.contact-info-container p {

  font-size: larger;

}
.contact-icon {

  cursor: default;

}

/* contact e-mail forms */
form.email-form {

  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  gap: 0.5rem;
  border: 2px solid #1aac83;
  border-radius: 10px;
  box-sizing: border-box;

}
form.email-form h3{

  text-align: center;
  font-size: 30px;
  color: #1aac83;
  text-decoration-line: underline;
  text-decoration-thickness: 5%;
  text-decoration-color: #1aac83;

}
form.email-form button {

  background-color: #1aac83;
  width: 100%;
  font-weight:bold;

}
.message {

  width: 100%;
  height: 200px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 30px;
  resize: none;

}



.section__text__p1 {
  font-size: 1.2rem;
  color: #555;
  text-align: center;

}
.title {
  color: #555;
  font-size: 2.5rem;
  text-align: center;

}


/* FOOTER SECTION */
footer {

  height: 26vh;
  margin:0 1rem;

}
footer nav{

  justify-content: center;

}
footer p {

  text-align: center;

}
.nav-links-footer {
  z-index: 1;
  gap: 2rem;
  list-style: none;
  font-size: 1.3rem;

}
footer nav a:hover {

  font-size: 1.1rem;
  color: #1aac83;
  text-decoration: none;
  transition: all 0.3 ease-in-out;

}
footer p a:hover {

  font-size: 1rem;
  color: #1aac83;
  text-decoration: none;
  transition: all 0.3 ease-in-out;

}
.modal{
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  /*background-color: rgb(0,0,0); *//* Fallback color */
  /*background-color: rgba(0,0,0,0.4); *//* Black w/ opacity */
}

.loader {
  margin: 14% 46%;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #2980B9;
  border-right: 16px solid #1aac83;
  border-bottom: 16px solid #D4300D;
  border-left: 16px solid #EC8B35;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.diaries form button{
  justify-content: center;
  text-align: center;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(720deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(720deg); }
}



/* mediaqueries.css */
@media screen and (max-width:1400px) {
    .contact {
      height: fit-content;
    }
    .loader {

      width: 100px;
      height: 100px;
      margin: 50% 40%;
  
    }
    
}
@media screen and (max-width:1200px) {
  .nav-links, .nav-links-connect {
    display: none;
  }
  .hamburger-nav {
    display: flex;
  }
  .section,
  .section-container {
    height: fit-content;
  }
  .section {
    margin: 0 5%;
  }
  .diary-details {

    width: 60%; 
  
  }
  .loader {

    width: 100px;
    height: 100px;
    margin: 40% 40%;

  }
  

}
@media screen and (max-width:600px) { 
  
  .home {
    padding-top: 20px;
  }
  
  .nav-links li a {
    font-size: 1rem;
  }
  .nav-links {
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
  }
  .footer {
    height: 40vh;
  }
  .footer nav {
    height: fit-content;
    margin-bottom: 2rem;
  }
  .contact-info-upper-container {
    flex-wrap: wrap;
  }
  .contact-info-container {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
  }
  .contact-info-container p,
  .nav-links li a {
    font-size: 1rem;
  }
  .userSignature img {
    max-width: 75%;
  }
  .diary-details { 
    width: fit-content;
  }
  .diariesHistory {
    
    margin-top: 30px;
    font-size: 20px;
    
  }
  .createDiaryForm textarea {
    min-height: 18em;
    min-width: 24em;
    padding: 5px;
  }
  
  .Section4 textarea {
    min-height: 18em;
    min-width: 23em;
    padding: 5px;
  }
  .inspection textarea {
    min-height: 18em;
    min-width: 23em;
    padding: 5px;
  }
  .overtimeWork textarea {
    min-height: 18em;
    min-width: 23em;
    padding: 5px;
  }
  .significantWorks textarea {
    min-height: 18em;
    min-width: 23em;
    padding: 5px;
  }
  .Section8 textarea {
    min-height: 18em;
    min-width: 23em;
    padding: 5px;
  }
  .Section1 {

    flex-wrap: wrap;
    gap: 5px;
    
  }
  .Section1_1 {

    gap: 5px;

  }
  .centerSection1_1 {

    margin-top: 10px;

  }
  .Section1_2 {

    gap: 5px;

  }
  .centerSection1_2 {

    margin-top: 15px;

  }
  .Section1_3 {

    gap: 5px;

  }
  .centerSection1_3 {

    margin-top: 15px;

  }
  .canvas .sigCanvas {
    height: 14em;
    width: 20em;
    
  }
  .canvas .error {
    height: 14em;
    width: 20em;
  }
  .wrapper {
    
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    text-align: center;
    padding: 5px;

  }
  .wrapper .icon {

    width: 50px;

  }
  .diaryForm .icon i {

    width: 35px;
    height: 35px;
    line-height: 35px;

  }
  .diaryForm .icon:first-child i{

    margin-left: 0px;

  }
  .diaryForm .icon:last-child  i{

    margin-right: 0px;

  }
  .wrapper .tabs-box {  

    margin-left: 0px;
    margin-right: 0px;

  }
  .tabs-box .tab {

    font-size: 0.8rem;
    font-weight: bold;
    
  }
  .createDiaryForm form div.error {
    margin-left: 10px;
    margin-right: 10px;
  }
  .signatureButtons button {

    width: 90px;
    
  }
  .submitDiary button {
    position:fixed;
    bottom: 0;
    right: 0;
    margin: 40px 25px;
    z-index: 100;
    
  }

  .submitDiary button:before {
    content: 'Καταχώρηση';
  }

  .diary-details .project-buttons {
    
    gap: 10px;

  }
  
  .staff-details {
  
    width: 90%;
    
  }

  .loader {

    width: 80px;
    height: 80px;
    margin: 70% 35%;

  }

  .canvas1{

    top:0px;
    
  }

  .startnow {

    padding-bottom: 20px;
    
  }

  .section__text__p1 {

    font-size: 1.0rem;
    
  }
  .title {

    font-size: 2rem;
  
  }
   
}